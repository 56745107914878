<template>
  <v-container xs12 sm6 offset-sm3>
    <v-row justify="center">
      <div class="hoofd" @click="begin">
        <transition
          enter-active-class="animate__animated animate__flipInY"
          appear
        >
          <v-img
            contain
            class="logoo"
            src="img/hoofdlight2.png"
            :width="imageHeight"
            alt="logo"
            style="cursor: pointer"
          />
        </transition>
      </div>
    </v-row>
    <v-row justify="center">
      <div class="tekst">
        <transition
          enter-active-class="animate__animated animate__slideInDown"
          appear
        >
          <v-img
            contain
            class="logoo2"
            src="img/tekstzwart.png"
            :width="imageHeight2"
            alt="logo"
          />
        </transition>
      </div>
    </v-row>
    <v-row justify="center">
      <div class="verder primary--text">
        <transition
          enter-active-class="animate__animated animate__fadeInLeft"
          appear
        >
          <p class="logoo3">Click on the logo to continue</p>
        </transition>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "Start",
  data() {
    return {
      animated: false,
      tokkie: null,
      token: null,
      voornaam: null,
      achternaam: null,
      voornaam_acc: null,
      achternaam_acc: null
    };
  },
  methods: {
    begin() {
      this.tokkie = JSON.parse(localStorage.getItem("tokkie"));
      // eslint-disable-next-line
      console.log("gaat goed");
      let data = JSON.stringify({
        tokkie: this.tokkie
      });

      axios
        .post("/websitePQSloginEn", data, {
          headers: {}
        })
        .then(response => {
          let app = this;
          // eslint-disable-next-line
          console.log(response);
          app.token = response.data.data.token;
          if (this.token) {
            // eslint-disable-next-line
            console.log("gaat heel goed gaat niet door");
            app.voornaam = response.data.data.voornaam;
            app.achternaam = response.data.data.achternaam;
            app.bedrijfsnaam = response.data.data.bedrijfsnaam;
            app.voornaam_acc = response.data.data.user.voornaam;
            app.achternaam_acc = response.data.data.user.achternaam;
            this.$store.commit("change", response.data.data.token);
            localStorage.setItem("token", JSON.stringify(this.token));
            localStorage.setItem("voornaam", JSON.stringify(this.voornaam));
            localStorage.setItem("achternaam", JSON.stringify(this.achternaam));
            localStorage.setItem(
              "bedrijfsnaam",
              JSON.stringify(this.bedrijfsnaam)
            );
            localStorage.setItem(
              "voornaam_acc",
              JSON.stringify(this.voornaam_acc)
            );
            localStorage.setItem(
              "achternaam_acc",
              JSON.stringify(this.achternaam_acc)
            );
            this.$router.push({ name: "Intro" });
          }
          // eslint-disable-next-line no-empty
          else {
            // eslint-disable-next-line
            console.log("en wat about else");
            this.$swal({
              icon: "warning",
              title: "Oops something goes wrong",
              text:
                "If you accessed this site via a button, try to copy the url as in the mail into the browser.",
              footer:
                "Should you continue to experience problems, we would appreciate it if you would contact us."
            });
          }
        })
        .catch(function(error) {
          // eslint-disable-next-line
          console.log(error);
        });
    }
  },

  computed: {
    // eslint-disable-next-line
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200px";
        case "sm":
          return "350px";
        case "md":
          return "350px";
        case "lg":
          return "350px";
        case "xl":
          return "600px";
      }
    },
    // eslint-disable-next-line
    imageHeight2() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200px";
        case "sm":
          return "350px";
        case "md":
          return "350px";
        case "lg":
          return "350px";
        case "xl":
          return "600px";
      }
    }
  }
};
</script>

<style scoped>
.hoofd {
  padding-top: 20vh;
}

.logoo {
  animation-duration: 3s;
}

.logoo2 {
  animation-duration: 1s;
  animation-delay: 3s;
  padding-top: 5vh;
}
.logoo3 {
  animation-duration: 1s;
  animation-delay: 3s;
  padding-top: 5vh;
}

.verder {
  animation-delay: 3s;
}

v-image {
  margin-left: 20px;
}
</style>

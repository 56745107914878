<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <toolbar></toolbar>
    <div class="container breedte">
      <div class="row">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-card-text class=" body-1 spec--text">
                Dear {{ voornaam }} {{ achternaam }},<br />
                <br />
                Welcome to Trusted Accountant. The aim of this Personal Quick
                Scan is to let you experience the user-friendliness of this app
                is. We use this app to make generic information applicable to
                the question and answer applicable to the situation of the
                customer. customer's situation. As a result, the customer
                experiences this information as more personal, more direct and
                more valuable. Positive effects are The customer gains more
                awareness, better insight into the issue and comes to a better
                decision. Or better prepared to the next conversation with
                you.<br /><br />

                Since you are reading through this app in your role as a
                partner/leadership in an accounting firm, we would like to take
                the opportunity opportunity to ask you a number of questions
                about developments in the developments in the accountancy
                branch. In this way you will get to know the various forms of
                questions and answers that we can we can deploy, as well as our
                vision as a service provider of accountancy firms.<br /><br />
                If you want to view this app in Dark Mode, you can at the top
                right activate this. <br /><br />
                <br /><br />Kind regards,<br /><br />
                {{ voornaam_acc }} {{ achternaam_acc }}<br /><br />
                With the button Continue you start with the questions
              </v-card-text>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class="row mt-8">
            <div class="col-3"></div>
            <div class="col-3">
              <Knopverder :on-click="next"></Knopverder>
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/core/Toolbar";
import Knopverder from "@/components/knopverder";

export default {
  name: "Module1.vue",
  components: { Knopverder, Toolbar },
  data() {
    return {
      goDark: false,
      voornaam: null,
      achternaam: null,
      voornaam_acc: null,
      achternaam_acc: null
    };
  },
  methods: {
    next() {
      // eslint-disable-next-line
      console.log("Verzenden");
      this.$router.replace("/topica");
    }
  },

  mounted() {
    // eslint-disable-next-line
    console.log("App mounted!");
    this.token = JSON.parse(localStorage.getItem("token"));
    this.voornaam = JSON.parse(localStorage.getItem("voornaam"));
    this.achternaam = JSON.parse(localStorage.getItem("achternaam"));
    this.voornaam_acc = JSON.parse(localStorage.getItem("voornaam_acc"));
    this.achternaam_acc = JSON.parse(localStorage.getItem("achternaam_acc"));
  }
};
</script>

<style scoped>
.embed-container {
  width: 100% !important;
}

@media (min-width: 200px) {
  p {
    font-size: 0.8em;
  }

  h1 {
    font-size: 0.9em;
    line-height: 1.5;
  }

  h2 {
    font-size: 1.6em;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.4em;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  p {
    font-size: 1em;
    line-height: 1.5;
  }

  h1 {
    font-size: 1.2em;
    line-height: 1.5;
  }

  h2 {
    font-size: 2em;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.7em;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 1.2em;
    line-height: 1.5;
  }

  h1 {
    font-size: 1.5em;
    line-height: 1.5;
  }

  h2 {
    font-size: 2.3em;
    line-height: 1.5;
  }

  h3 {
    font-size: 2em;
    line-height: 1.5;
  }

  .iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

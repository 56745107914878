<template>
  <div>
    <toolbar></toolbar>
    <div class="container breedte">
      <div id="element1" class="row" v-if="stap === 1">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <v-card-title class="primary--text">
              THE CHALLENGE
            </v-card-title>
            <v-card-text class=" body-1 spec--text">
              The accounting industry is facing a major IT effort to further
              streamline the primary process. At the same time the possibilities
              of realizing new turnover within the annual work are decreasing.
              realization are diminishing. This means that new opportunities are
              being sought, for example, in advisory products outside the fields
              of accountancy and tax law. However, partly in view of the
              shortage on the labor market, this poses new challenges for the
              office organization. labor market, this creates new challenges for
              the office organization.<br /><br />
              To what extent do you agree with the following statement:
            </v-card-text>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                In the longer term, the main challenge is to adapt the culture
                within the accountancy firm, so that room is created for renewal
                and innovation in the SME advisory practice.
              </v-card-title>
              <ta-slider3 v-model="question_a" />
              <v-card-text class=" body-1 font-italic spec--text">
                A slider works very intuitively on tablets and smartphones. You
                slide with your finger across the screen to the position that
                fits with your answer. In the report, this result is reflected
                as a number between -100 (totally disagree) and 100 (totally
                agree).
              </v-card-text>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="stapA"
                  v-scroll-to="{ el: '#element1' }"
                >
                  Continue
                </v-btn>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="element2" class="row" v-if="stap === 2">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <v-card-title class="primary--text">
              ATTRACTIVENESS AS AN EMPLOYER
            </v-card-title>
            <v-card-text class=" body-1 spec--text">
              Due to the tightness of the labor market, it is difficult to
              recruit and retain good people and retaining good people. It is
              not only the primary and terms of employment play a role, but also
              the attractiveness of the job. (Top) talent expects to be
              challenged in the assignments they carry out. In their opinion,
              this goes beyond compiling annual accounts. They also want to work
              with technical innovations such as data analysis or with new
              consultancy concepts.
            </v-card-text>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                Does your office have a policy to offer (top) talents these
                challenges offer, for example, a High Potentials Program?
              </v-card-title>
              <div class="row">
                <div class="col-12 ml-5">
                  <v-form v-model="valid" ref="form_b" lazy-validation>
                    <v-radio-group
                      v-model="question_b"
                      :rules="radioRules"
                      required
                    >
                      <v-radio value="ke1" color="primary">
                        <template v-slot:label>
                          <div class="spec--text">
                            Yes
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="ke2" color="primary">
                        <template v-slot:label>
                          <div class="spec--text">
                            No
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <v-btn
                      color="primary"
                      @click="stapB"
                      v-scroll-to="{ el: '#element2' }"
                    >
                      Continue</v-btn
                    >
                  </v-form>
                </div>
                <v-card-text class=" body-1 font-italic spec--text">
                  A so-called radio button, here as Yes and No, can be used in a
                  different ways. In this case we force you to give a factual
                  answer. These kinds of questions can help to take a good
                  inventory.
                </v-card-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="element3" class="row" v-if="stap === 3">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                INNOVATION
              </v-card-title>
              <v-card-text class=" body-1 spec--text">
                This is an important incentive to make processes faster and more
                efficient in composition practice. The SME advisory practice is
                lagging behind in this respect. Our vision is that streamlining
                advisory processes leads to higher added value for the added
                value for the client. We foresee that the customer will no
                longer be willing to pay for steps in the advisory process that
                are also process that can also be solved by means of technology.
                can be solved through technology. At the same time we see that
                entrepreneurs want to keep control over what is important and
                urgent for them. important and urgent. They expect their
                advisors to support them in this. For these reasons, we expect
                the role of IT in advisory processes will be greatly expanded in
                the coming years. expanded.
              </v-card-text>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                Is this an important issue for your office?
              </v-card-title>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="duimlaagC"
                  v-scroll-to="{ el: '#element3' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-down
                  </v-icon>
                </v-btn>
              </div>
              <div class="col-3">
                <v-btn
                  large
                  color="secondary"
                  @click="duimopC"
                  v-scroll-to="{ el: '#element3' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-up
                  </v-icon>
                </v-btn>
              </div>
              <v-card-text class=" body-1 font-italic spec--text">
                With thumps up or down you can gauge interest in a topic or
                issue in a playful, modern way.
              </v-card-text>
            </div>
          </div>
        </div>
      </div>
      <div id="element4" class="row" v-if="stap === 4">
        <div class="col-sm-6 col-12 ">
          <div class="container ml-0 pl-1">
            <v-card-title class="primary--text">
              PROCESS OPTIMIZATION
            </v-card-title>
            <div class="col-3 px-12">
              <v-btn large color="secondary" @click="createPPT">
                PPTX
              </v-btn>
            </div>
            <v-card-text class=" body-1 spec--text">
              With the above button you can compose a PowerPoint. In your own
              online platform. You can create a custom PowerPoint of any
              QuickScan. PowerPoint, often within 2 to 3 seconds. So no more
              changing names, date, etc. But a presentation tailored tailored
              for your client! <br />
              <br />The PowerPoint provides an overview of the questions asked
              and your answers. This gives you an important tool for the
              customer conversation. Starting point is the input of the
              customer. To each answer can be linked to a set of follow-up
              questions. In this way you help the customer to further shape his
              thoughts. In this way you contribute to the understanding of the
              issue and thus increase the willingness to get to work on the
              subject.<br />
              <br />
              It makes a significant contribution to the entire customer
              experience while providing speed, uniformity and compliancy in the
              consulting process.
            </v-card-text>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                What objections do you expect within the office if you were to
                introduce this method of working? You can choose several
                options.
              </v-card-title>
              <v-checkbox v-model="question_d" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Low willingness to change ("yet another new system that we
                    are not going to use")
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_e" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Does not fit with our method of working ("not invented
                    here-syndrome")
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_f" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Eeach customer is unique, there is no uniform method of
                    working for them
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_g" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Data protection and privacy
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_h" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Other reason, namely
                  </div>
                </template>
              </v-checkbox>
              <div v-if="question_h">
                <v-textarea
                  outlined
                  shaped
                  placeholder="Other reason"
                  v-model="question_i"
                  :counter="200"
                  required
                />
              </div>
              <v-btn
                color="primary"
                @click="duimopD"
                kv-scroll-to="{ el: '#element4'}"
                >Continue</v-btn
              >
            </div>
            <v-card-text class=" body-1 font-italic spec--text">
              Checkboxes are a good tool for examining the customer's motives
              research. By listing different motives in advance, it also becomes
              possible to compare this with the results of other customers.
              other customers.
            </v-card-text>
          </div>
        </div>
      </div>
      <div id="element5" class="row" v-if="stap === 5">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <div>
                <vimeo-player
                  :video-id="490769243"
                  class="embed-container ml-0"
                  :options="{ responsive: true }"
                ></vimeo-player>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                USE OF ANIMATIONS
              </v-card-title>
              <v-card-text class=" body-1 spec--text">
                The attached animation provides a complete understanding of a
                topic. People are often visually oriented and therefore find it
                easier to look at something than to read a whole article. Images
                say more than 1,000 words. We frequently use animations to get a
                message across quickly and clearly. quickly and clearly.
                Animations can be used for virtually any subject.
              </v-card-text>
              <v-card-title class="primary--text">
                Do animations fit your customers' communication preferences?
              </v-card-title>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="duimlaagE"
                  v-scroll-to="{ el: '#element5' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-down
                  </v-icon>
                </v-btn>
              </div>
              <div class="col-3">
                <v-btn
                  large
                  color="secondary"
                  @click="duimopE"
                  v-scroll-to="{ el: '#element5' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-up
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="element6" class="row" v-if="stap === 6">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-img contain src="img/pcm.jpg" max-height="600" alt="logo" />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                WHAT DO YOU THINK OF PERSONAL CONTENT MARKETING?
              </v-card-title>
              <v-card-text class=" body-1 spec--text">
                Personal Content Marketing offers numerous opportunities for
                your office. Think of sharing knowledge in a modern way, whereby
                the customer himself has the direction as to how and when he is
                going to will go through and apply this knowledge. You can prior
                to a customer meeting to gauge the customer's interest in
                various gauge the customer's interest in various issues. You can
                link social media campaigns to landing pages. All this in the
                house style of your office and via your own online platform.
                <br /><br />
                How many stars would you give Personal Content Marketing?
              </v-card-text>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <v-rating
                hover
                length="5"
                size="64"
                v-model="question_k"
                half-increments
              ></v-rating>
              <div class="col-3">
                <v-btn
                  large
                  color="secondary"
                  @click="duimopF"
                  v-scroll-to="{ el: '#element5' }"
                  >Continue
                </v-btn>
              </div>
            </div>
            <v-card-text class=" body-1 font-italic spec--text">
              A rating is useful for asking the customer for an overall
              assessment or rating.
            </v-card-text>
          </div>
        </div>
      </div>
      <div id="element7" class="row" v-if="stap === 7">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-img
                contain
                src="img/img_360.png"
                max-height="600"
                alt="logo"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <div class="primary--text">
                <h3>End</h3>
              </div>

              <v-card-text class=" body-1 spec--text">
                You have come to the end of this short Personal Quick Scan. You
                have now learned about our vision, filled in various questions
                and gained experience with the user friendliness. We have
                limited the content to an animation and texts, but it is very
                well possible to use other media such as pictures.

                <br /><br />
                When you're done filling out, press the Send button. Your will
                receive a report (PDF format) in your e-mail box. The app will
                close automatically.
                <br /><br />
                Thanks for filling in.
                <br /><br />
                On behalf of the team behind Trusted Accountant<br />
                {{ voornaam_acc }} {{ achternaam_acc }}
              </v-card-text>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="eind"
                  v-scroll-to="{ el: '#element7' }"
                >
                  End
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Toolbar from "@/components/core/Toolbar";
import TaSlider3 from "@/components/slider/ta-slider3";

export default {
  name: "Module1.vue",
  components: {
    TaSlider3,

    Toolbar
  },
  data() {
    return {
      bedrijfsnaam: "",
      voornaam: null,
      achternaam: null,
      voornaam_acc: null,
      achternaam_acc: null,
      stap: 1,
      question_a: 0,
      question_b: "",
      question_c: false,
      question_d: false,
      question_e: false,
      question_f: false,
      question_g: false,
      question_h: false,
      question_i: "",
      question_j: false,
      question_k: 2.5,
      radioRules: [
        v => !!v || "You can't move forward without first making a choice."
      ],
      goDark: false,
      valid: true,
      status1: false,
      token: null
    };
  },
  methods: {
    createPPT() {
      let colorA = "003895";
      let koptekst2 = "PowerPoint";
      let koptekst3 = "The challenge";
      let koptekst4 = "Attractiveness as an employer";
      let koptekst5 = "Opportunities";
      let koptekst6 = "Possibilities";
      let koptekst15 = "Learn More";

      // eslint-disable-next-line no-unused-vars
      let gTitelplaats = { x: 0.25, y: 0.7, w: 9.5, h: 0.46, align: "left" };
      // eslint-disable-next-line no-unused-vars
      let gTitel = {
        fontFace: "Arial",
        fontSize: 33,
        color: colorA,
        valign: "top"
      };
      let opsom1 = {
        bullet: false,
        fontSize: 12,
        fontFace: "Calibri",
        breakLine: true
      };
      let opsom2 = {
        bullet: false,
        fontSize: 12,
        bold: true,
        fontFace: "Calibri",
        breakLine: true
      };
      let opsom3 = {
        bullet: { indent: 10 },
        fontSize: 12,
        bold: false,
        fontFace: "Calibri",
        breakLine: true
      };
      let opsom4 = {
        bullet: { type: "number", indent: 10 },
        fontSize: 12,
        bold: false,
        fontFace: "Calibri",
        breakLine: true
      };
      let tekstblok = {
        x: 0.5,
        y: 1.26,
        w: 8.56,
        h: 3.88,
        fontFace: "Calibri",
        fontSize: 12,
        valign: "top"
      };
      let tekstblok2 = {
        x: 0.5,
        y: 1.26,
        w: 8.56,
        h: 2.12,
        fontFace: "Calibri",
        fontSize: 12,
        valign: "top"
      };
      let tekstblok3 = {
        x: 0.15,
        y: 1.25,
        w: 5.94,
        h: 3.88,
        fontFace: "Calibri",
        fontSize: 12,
        valign: "top"
      };
      // eslint-disable-next-line no-undef
      let pptx = new pptxgen();
      pptx.defineSlideMaster({
        title: "MASTER_SLIDE",
        bkgd: "FFFFFF",
        objects: [
          {
            text: {
              text: "-TRUSTED ACCOUNTANT-",
              options: {
                x: 0.6,
                y: 5.23,
                w: 6.5,
                h: 0.33,
                fontFace: "Calibri",
                fontSize: 6.8,
                charSpacing: 5,
                color: "9D968D",
                valign: "top"
              }
            }
          },
          {
            image: {
              x: 0,
              y: 5.03,
              w: 4.79,
              h: 0.3,
              path: "./img/kleurbalk.png"
            }
          }
        ],
        slideNumber: {
          x: 0.42,
          y: 5.23,
          fontFace: "Calibri",
          fontSize: 6.8,
          color: "9D968D"
        }
      });

      let slide1 = pptx.addSlide();
      slide1.addImage({
        path: "./img/start.jpg",
        x: 0,
        y: 0,
        w: "100%",
        h: "100%"
      });

      slide1.addShape(pptx.shapes.RECTANGLE, {
        x: 6.63,
        y: 0,
        w: 3.37,
        h: 5.63,
        fill: { color: "003895", transparency: "50" }
      });
      slide1.addText("The Future of Advice", {
        x: 2.52,
        y: 2.18,
        w: 4.11,
        h: 0.66,
        fontSize: 33,
        fontFace: "Calibri",
        color: "ffffff",
        align: "center",
        valign: "bottom"
      });
      slide1.addImage({
        path: "./img/logo_light.png",
        x: 7.27,
        y: 0.35,
        w: 2.09,
        h: 2.46
      });
      slide1.addText(this.bedrijfsnaam, {
        x: 7.25,
        y: 2.3,
        w: 2.3,
        h: 0.35,
        fontSize: 13.5,
        fontFace: "Calibri",
        color: "ffffff",
        align: "left",
        valign: "bottom"
      });
      slide1.addText(this.voornaam + " " + this.achternaam, {
        x: 7.25,
        y: 3.9,
        w: 2.3,
        h: 0.35,
        fontSize: 13.5,
        fontFace: "Calibri",
        color: "ffffff",
        align: "left",
        valign: "bottom"
      });
      let slide2 = pptx.addSlide("MASTER_SLIDE");
      slide2.addText([{ text: koptekst2, options: gTitel }], gTitelplaats);
      slide2.addText(
        [
          {
            text: "Beste " + this.voornaam + " " + this.achternaam,
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "You are completing the app to experience how Personal Content Marketing works. This PowerPoint is an essential part of the customer experience. It allows for a seamless transition between the app, the customer's input into the app and the consultation. We explain how that works below.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "In the online environment, the input from the PCM tool is available. The relationship manager makes a PowerPoint presentation based on this input in his meeting preparation. Within seconds, this is available in the house style and fully completed. The customer's input can easily be discussed in the interview via the PowerPoint. The PowerPoint also provides more depth for each issue by listing the appropriate follow-up questions. This gives the relationship manager a powerful tool to further identify customer needs.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Important advantages of this method are the positive customer experience, speed and efficiency of the advisory process and compliancy regarding content. The PowerPoint is an important tool in the toolbox of the relationship manager.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "In the following sheets, some examples of how this can work.",
            options: opsom1
          }
        ],
        tekstblok
      );
      let slide3 = pptx.addSlide("MASTER_SLIDE");
      slide3.addText([{ text: koptekst3, options: gTitel }], gTitelplaats);
      slide3.addText(
        [
          {
            text: "The first question in the test app concerned:",
            options: opsom1
          },
          {
            text:
              "In the longer term, the main challenge is to adapt the culture within the accounting firm to create room for renewal and innovation in the SME advisory practice.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "The answer could be given by means of a slider with as extremes 'Completely disagree' and 'Completely agree'. The position you indicated on the slider was " +
              this.question_a +
              "We call this value the Feeling Score.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "In the feedback to the respondent, you can link this Feeling Score to a number of classes. Texts and follow-up questions can then be linked to these classes. For example:",
            options: opsom1
          }
        ],
        tekstblok2
      );
      let rows3 = [];
      rows3.push([
        {
          text: "Feeling score",
          options: { bold: true }
        },
        {
          text: "Text and follow-up question",
          options: { bold: true }
        }
      ]);
      rows3.push([
        {
          text: "-100 tot -75"
        },
        {
          text:
            "You disagree with the statement. Which challenges are more important to you?"
        }
      ]);
      rows3.push([
        {
          text: "-75 tot -25"
        },
        {
          text:
            "You don't expect that culture is not the most important issue. How does this issue compare to other challenges?"
        }
      ]);
      rows3.push([
        {
          text: "-25 tot +25"
        },
        {
          text:
            "You doubt that culture is the most important challenge. What considerations are you making in this regard?"
        }
      ]);
      rows3.push([
        {
          text: "25 tot 75"
        },
        {
          text:
            "You expect culture to be the most important issue. What elements of the current culture do you want to retain?"
        }
      ]);
      rows3.push([
        {
          text: "75 tot 100"
        },
        {
          text:
            "You agree with the statement. What steps have you taken to meet this challenge?"
        }
      ]);

      slide3.addTable(rows3, {
        x: 0.5,
        y: 3.15,
        w: 9.3,
        colW: [1, 8.3],
        fontSize: "12",
        fontFace: "Calibri",
        color: "000000"
      });
      let slide4 = pptx.addSlide("MASTER_SLIDE");
      slide4.addText([{ text: koptekst4, options: gTitel }], gTitelplaats);
      slide4.addText(
        [
          {
            text: "The second question in the test app concerned:",
            options: opsom1
          },
          {
            text:
              "Does your firm have a policy for offering (top) talent these challenges, for example, a High Potentials Program?",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "You could give the answer by means of Yes or No.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Depending on the answer, you can attach further information and follow-up questions to this. For example, for the answer No:",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Should you be considering setting up a High Potential program, here are some important steps:",
            options: opsom1
          },
          {
            text: "Campus recruitment means ….",
            options: opsom4
          },
          {
            text: "Mentoring means ….",
            options: opsom4
          },
          {
            text:
              "Create work environment for high potentials, these are the most important tips",
            options: opsom4
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "With the answer Yes, you can link follow-up questions:",
            options: opsom1
          },
          {
            text: "When did you start?",
            options: opsom3
          },
          {
            text:
              "What are your experiences in setting up and implementing the program?",
            options: opsom3
          },
          {
            text: "What elements in the program are you satisfied with?",
            options: opsom3
          },
          {
            text:
              "What are the results so far in terms of development and retention of top talent?",
            options: opsom3
          },
          {
            text: "etcetera",
            options: opsom3
          }
        ],
        tekstblok
      );

      let slide5 = pptx.addSlide("MASTER_SLIDE");
      slide5.addText([{ text: koptekst5, options: gTitel }], gTitelplaats);
      slide5.addText(
        [
          {
            text:
              "Personal Content Marketing offers numerous opportunities to share knowledge with customers.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "It can be used for various communication purposes, from raising awareness to inciting the customer to take action. To gauge interest in an issue or to get all the necessary information for the consultation.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "The choice regarding communication medium may also vary. The online platform works with animations, expert videos, surveys, white papers, PowerPoint presentations, documents, etc.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Important advantages of this method are the positive customer experience, speed and efficiency of the advisory process and compliancy regarding content. The PowerPoint is an important tool in the toolbox of the relationship manager.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "We developed tools for different communication channels such as the advisor directly with the customer, collective actions to a customer group, landing pages for the corporate website and for social media campaigns.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "On the next page an animation with a further explanation.",
            options: opsom2
          }
        ],
        tekstblok
      );

      let slide6 = pptx.addSlide("MASTER_SLIDE");
      slide6.addText([{ text: koptekst6, options: gTitel }], gTitelplaats);
      slide6.addMedia({
        type: "video",
        path: "./video/videopcm.mp4",
        x: 0.5,
        y: 1.2,
        w: 3.84,
        h: 2.74
      });
      slide6.addText(
        "In this animation you will learn about the different possibilities of Personal Content Marketing. It also gives you an idea of how powerful animations are to get a message across to a target audience.",
        {
          x: 0.5,
          y: 4.3,
          w: 8.56,
          h: 0.9,
          fontSize: 12,
          fontFace: "Calibri",
          align: "left",
          valign: "top"
        }
      );
      let slide15 = pptx.addSlide("MASTER_SLIDE");
      slide15.addText([{ text: koptekst15, options: gTitel }], gTitelplaats);
      slide15.addShape(pptx.shapes.RECTANGLE, {
        x: 6.36,
        y: 0,
        w: 3.64,
        h: 5.63,
        fill: { color: "003478" }
      });

      slide15.addText(
        [
          {
            text: "Trusted Accountant",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              bold: true,
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "W. Vrijlandtstraat 8",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "3262 GN" + " Oud-Beijerland",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              breakLine: true
            }
          },
          {
            text: "www.trusted-accountant.com",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              breakLine: true
            }
          },

          {
            text: "Arie Heerschap",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Arial",
              color: "FFFFFF",
              breakLine: true
            }
          },

          {
            text: "email: a.heerschap@trustedaccountant.nl",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Arial",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "phone: +31 (0)6 1963 1986",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Arial",
              color: "FFFFFF",
              breakLine: true
            }
          }
        ],
        { x: 6.88, y: 2.45, w: 2.68, h: 2.68 }
      );
      slide15.addText(
        [
          {
            text:
              "“What are we making possible with your content and our technology?”",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "We always put this question at the center of our discussions with accounting firms. There is much more possible than you initially expect. Our job is to unlock your knowledge to your clients and streamline consulting processes using our technology. The customer's communication preferences are provided for, while the requirements of speed, efficiency, uniformity and compliancy are better met. Indispensable elements in The Future of Advice.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "Curious? Let's have a sparring match.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text: "trusted-accountant.com",
            options: opsom1
          },
          {
            text: "Eric Laaper en Arie Heerschap",
            options: opsom1
          },
          {
            text: "Telefoon/WhatsApp: +31 (0)6 1963 1986",
            options: opsom1
          },
          {
            text: "E-mail: arie.heerschap@trustedaccountant.nl",
            options: opsom1
          }
        ],
        tekstblok3
      );
      slide15.addImage({
        path: "./img/logo_light.png",
        x: 7.27,
        y: 0.35,
        w: 2.09,
        h: 2.46
      });

      let filename = "Presentation_PQS_" + this.achternaam + ".pptx";
      // 4. Save the Presentation
      pptx.writeFile(filename);
    },

    stapA() {
      this.status1 = true;
      this.next_topic();
    },
    stapB() {
      if (this.$refs.form_b.validate()) {
        this.next_topic();
      }
    },

    duimlaagB() {
      this.topic_260 = false;
      this.next_topic();
    },
    duimlaagC() {
      this.topic_308 = false;
      this.next_topic();
    },
    duimlaagD() {
      this.topic_312 = false;
      this.next_topic();
    },
    duimlaagE() {
      this.question_j = false;
      this.next_topic();
    },
    duimlaagF() {
      this.topic_352 = false;
      this.next_topic();
    },
    duimlaagG() {
      this.topic_360 = false;
      this.next_topic();
    },
    duimlaagH() {
      this.topic_372 = false;
      this.next_topic();
    },
    duimlaagI() {
      this.topic_455 = false;
      this.next_topic();
    },
    duimlaagEind() {
      this.stap = 1;
    },

    duimopA() {
      this.status1 = true;
      this.topic_205 = true;
      this.next_topic();
    },
    duimopB() {
      this.topic_260 = true;
      this.next_topic();
    },
    duimopC() {
      this.topic_308 = true;
      this.next_topic();
    },
    duimopD() {
      this.topic_312 = true;
      this.next_topic();
    },
    duimopE() {
      this.question_j = true;
      this.next_topic();
    },
    duimopF() {
      this.topic_352 = true;
      this.next_topic();
    },
    duimopG() {
      this.topic_360 = true;
      this.next_topic();
    },
    duimopH() {
      this.topic_372 = true;
      this.next_topic();
    },
    duimopI() {
      this.topic_455 = true;
      this.next_topic();
    },
    duimopEind() {
      this.eind();
    },

    next_topic() {
      // eslint-disable-next-line
      console.log("Verzenden.......");
      let data = JSON.stringify({
        token: this.token,
        status1: this.status1,
        question_a: this.question_a,
        question_b: this.question_b,
        question_c: this.question_c,
        question_d: this.question_d,
        question_e: this.question_e,
        question_f: this.question_f,
        question_g: this.question_g,
        question_h: this.question_h,
        question_i: this.question_i,
        question_j: this.question_j,
        question_k: this.question_k
      });
      axios.post("/websitePQSupdateEn", data, {
        headers: {}
      });
      this.$swal({
        icon: "success",
        title: "Saved",
        text: "Your choice is safely stored",
        showConfirmButton: false,
        timer: 1200
      })
        .then(response => {
          // eslint-disable-next-line
          console.log(response);

          this.stap++;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          this.$swal({
            icon: "warning",
            title: "Oops something goes wrong",
            text: "Watch out something is not going well",
            footer:
              "Please try again if you continue to have problems please contact us!"
          });
          this.$router.push({ name: "Start" });
        });
    },

    eind() {
      // eslint-disable-next-line
      console.log("Verzenden.......");
      let data = JSON.stringify({
        token: this.token,
        status1: this.status1,
        question_a: this.question_a,
        question_b: this.question_b,
        question_c: this.question_c,
        question_d: this.question_d,
        question_e: this.question_e,
        question_f: this.question_f,
        question_g: this.question_g,
        question_h: this.question_h,
        question_i: this.question_i,
        question_j: this.question_j,
        question_k: this.question_k
      });
      axios.post("/websitePQSeindEn", data, {
        headers: {}
      });
      this.$swal({
        icon: "success",
        title: "Saved",
        text: "Your choices are safely stored. you are now logged out.",
        showConfirmButton: false,
        timer: 1200
      })
        .then(response => {
          // eslint-disable-next-line
          console.log(response);
          localStorage.clear();
          localStorage.removeItem("token");
          localStorage.removeItem("tokkie");
          localStorage.removeItem("voornaam");
          localStorage.removeItem("achternaam");
          localStorage.removeItem("voornaam_acc");
          localStorage.removeItem("achternaam_acc");
          localStorage.removeItem("dark_theme");
          this.$router.push({ name: "Start" });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          this.$swal({
            icon: "warning",
            title: "Oops something goes wrong",
            text: "Watch out something is not going well",
            footer:
              "Please try again if you continue to have problems please contact us!"
          });
          this.$router.push({ name: "home" });
        });
    }
  },

  mounted() {
    // eslint-disable-next-line
    console.log("App mounted!");
    this.token = JSON.parse(localStorage.getItem("token"));
    this.voornaam = JSON.parse(localStorage.getItem("bedrijfsnaam"));
    this.voornaam = JSON.parse(localStorage.getItem("voornaam"));
    this.achternaam = JSON.parse(localStorage.getItem("achternaam"));
    this.voornaam_acc = JSON.parse(localStorage.getItem("voornaam_acc"));
    this.achternaam_acc = JSON.parse(localStorage.getItem("achternaam_acc"));
  }
};
</script>

<style scoped></style>
